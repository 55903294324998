import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { IoMdMenu } from "react-icons/io";
import { useLocation } from '@reach/router';
import Collapse from '@mui/material/Collapse';
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { StaticImage } from 'gatsby-plugin-image';
import { Link, navigate } from 'gatsby';
import Cart from '../components/cart';
import { FaUser, FaUserCircle, FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";

import { IoCallSharp } from "react-icons/io5";
import { FaLinkedinIn, FaXTwitter } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";

const DrawerMenu = ({ navLinks }) => {
    let shopifyToken;
    if (typeof window !== 'undefined') {
        shopifyToken = localStorage.getItem('sh-kal-ac');
    }

    const location = useLocation();
    const pathname = location.pathname;
    const [openSubMenu, setOpenSubMenu] = React.useState(null);
    const [state, setState] = React.useState({ left: false });

    const toggleDrawer = (anchor, open) => () => {
        setState(prev => ({ ...prev, [anchor]: open }));
    };

    const handleToggleSubMenu = (index) => {
        setOpenSubMenu(prev => (prev === index ? null : index));
    };

    React.useEffect(() => {
        setState(prev => ({ ...prev, left: false }));
    }, [pathname]);

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 260 }}
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100vh" }}>
                <List className='!py-0'>
                    <div className='px-[16px] py-[20px] bg-[#2984bd]'>
                        <Link to="/">
                            <StaticImage
                                src="../assets/images/logo.png"
                                alt="logo"
                                className="w-full max-w-[100px] mx-auto"
                            />
                        </Link>
                    </div>
                    {navLinks.map((item, index) => (
                        <React.Fragment key={index}>
                            <ListItem disablePadding className={`${pathname === item.handle ? "!text-[#2984bd]" : ""} border-solid border-b border-[#ddd] last:border-none`} onClick={() => handleToggleSubMenu(index)}>
                                <ListItemButton onClick={() => navigate(item.handle)}>
                                    <ListItemText primary={item.title} />
                                </ListItemButton>
                                {item.subLinks && (
                                    <ListItemButton className='!flex !justify-end !absolute right-0'>
                                        {openSubMenu === index ? <IoIosArrowUp /> : <IoIosArrowDown />}
                                    </ListItemButton>
                                )}
                            </ListItem>
                            {item.subLinks && (
                                <Collapse in={openSubMenu === index} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {item.subLinks.map((subItem, i) => (
                                            <ListItem
                                                key={i}
                                                disablePadding
                                                className={`${pathname === subItem.handle ? "!text-[#fff] bg-[#ef7f1a]" : "bg-[#2984bd]"} text-white border-solid border-b border-[#ffffff29] last:border-none`}
                                            >
                                                <ListItemButton
                                                    onClick={() => {
                                                        setState(prev => ({ ...prev, left: false })); // Close drawer first
                                                        setTimeout(() => {
                                                            navigate(subItem.handle); // Then navigate
                                                        }, 300);
                                                    }}
                                                >
                                                    <ListItemText className={`pl-[15px]`} primary={subItem.title} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            )}
                        </React.Fragment>
                    ))}
                    <div className='px-[16px] my-[20px]'>
                        <div className='text-left'>
                            <div className="flex flex-col gap-[5px] mb-[15px]">
                                <Link className="text-[#2984bd] hover:text-[#ef7f1a] flex items-center group" to='tel:+919056111675'>
                                    <IoCallSharp className='mr-[5px] text-[20px] group-hover:text-[#ef7f1a] group-hover:scale-[1.2] duration-700' />+91 90561-11675
                                </Link>
                                <Link className="text-[#2984bd] hover:text-[#ef7f1a] flex items-center group" to='mailto:sales@mycloudcam.in'>
                                    <IoMdMail className='mr-[5px] text-[20px] group-hover:text-[#ef7f1a] group-hover:scale-[1.2] duration-700' />sales@mycloudcam.in
                                </Link>
                            </div>
                            <div>
                                <div className="flex gap-[5px]">
                                    <Link to='https://www.facebook.com/mycloudcam/' target="_blank" className='text-[18px] text-[#fff] w-[32px] h-[32px] rounded-full bg-[#2984bd] hover:bg-[#ef7f1a] flex justify-center items-center'><FaFacebookF /></Link>
                                    <Link to='https://www.instagram.com/my_cloudcam/' target="_blank" className='text-[18px] text-[#fff] w-[32px] h-[32px] rounded-full bg-[#2984bd] hover:bg-[#ef7f1a] flex justify-center items-center'><FaInstagram /></Link>
                                    <Link to='https://twitter.com/My_cloudcam' target="_blank" className='text-[18px] text-[#fff] w-[32px] h-[32px] rounded-full bg-[#2984bd] hover:bg-[#ef7f1a] flex justify-center items-center'><FaXTwitter /></Link>
                                    <Link to='https://www.linkedin.com/company/my-cloud-cam/?viewAsMember=true' target="_blank" className='text-[18px] text-[#fff] w-[32px] h-[32px] rounded-full bg-[#2984bd] hover:bg-[#ef7f1a] flex justify-center items-center'><FaLinkedinIn /></Link>
                                    <Link to='https://www.youtube.com/@myCloudCAM' target="_blank" className='text-[18px] text-[#fff] w-[32px] h-[32px] rounded-full bg-[#2984bd] hover:bg-[#ef7f1a] flex justify-center items-center'><FaYoutube /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </List>
                
                <List className='bg-[#2984bd] flex !pl-[20px] !pr-[30px] !py-[20px]'>
                    <ListItem className='flex !p-[0px]'>
                        {!shopifyToken ? (
                            <Link to="/login" className='text-[18px] text-[#fff]' onClick={toggleDrawer('left', false)}>
                                <FaUser size={22} />
                            </Link>
                        ) : (
                            <Link to="/account" className='text-[18px] text-[#fff]' onClick={toggleDrawer('left', false)}>
                                <FaUserCircle size={22} />
                            </Link>
                        )}
                    </ListItem>
                    <ListItem className='flex !justify-end !p-[0px]'
                        onClick={() => {
                            setState(prev => ({ ...prev, left: false })); // Close drawer first
                            setTimeout(() => {
                                navigate('/cart'); 
                            }, 300); 
                        }}
                    >
                        <Cart />
                    </ListItem>
                </List>
            </Box>
        </Box>
    );

    return (
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}>
                        <IoMdMenu className='text-[36px] text-white' />
                    </Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
};

export default DrawerMenu;
