import React, { useContext } from 'react'
import { Link } from 'gatsby';
import { FaCartShopping } from "react-icons/fa6";
import { StoreContext } from '../../store';

const Cart = () => {
    const { cart } = useContext(StoreContext);
    return (
        <Link to="/cart" className='relative'>
            {cart && cart?.lineItems?.length > 0 &&
                <div className='bg-[#ef7f1a] w-[20px] h-[20px] flex justify-center items-center rounded-full absolute -top-[7px] -right-[10px]'>
                    <p className="m-0 text-white text-[12px] font-normal">{cart?.lineItems?.length}</p>
                </div>
            }
            <FaCartShopping className="cart-icon ml-[15px]" size={26} />
        </Link>)
}

export default Cart;