import React from "react";
import "./src/styles/global.css"

export const onRenderBody = ({ setHeadComponents }) => {
  setHeadComponents([
    <script
      key="google-analytics"
      async
      src={`https://www.googletagmanager.com/gtag/js?id=G-3LZY7MMMNR`}
    />,
    <script
      key="google-analytics-inline"
      dangerouslySetInnerHTML={{
        __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-3LZY7MMMNR', { anonymize_ip: true });
        `,
      }}
    />,
  ]);
};
